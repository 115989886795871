import * as React from "react"
import { Box, Main } from "grommet"

const Layout = ({ location, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <Box
      align="center"
      className="global-wrapper"
      data-is-root-path={isRootPath}
    >
      <Box width={{ max: "42rem" }}>
        <Main>{children}</Main>
      </Box>
    </Box>
  )
}

export default Layout
